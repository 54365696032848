"use client";

import Image from "next/image";
import { useState } from "react";
import { useRouter } from "next/navigation";

import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";

import SearchIcon from "/public/assets/home/hero/search-icon.png";
import ButterfliesImage from "/public/assets/home/hero/butterflies-hero.png";
import HeroImage from "/public/assets/home/hero/home-hero.png";
import LeavesColorImage from "/public/assets/home/hero/leaves-color.png";
import LeavesGreyImage from "/public/assets/home/hero/leaves-grey.png";
import DiagonalsImage from "/public/assets/home/hero/diagonals.png";

const popularSearch = ["Mobile Apps", "Software", "TikTok Videos", "Website"];

export default function Hero() {
	const router = useRouter();
	const [searchString, setSearchString] = useState("");

	const handleSearch = (searchString: string) => {
		router.push(`/search/${searchString}`);
	};

	return (
		<div className="bg-[#F1EFEB] pt-20 xs:pt-24 sm:px-8 sm:pt-12 md:px-12">
			<div className="container relative grid min-h-[31rem] px-4 sm:min-h-[30rem] sm:px-6 xl:grid-cols-[1fr_26rem]">
				<div className="z-10 flex flex-col justify-center text-3xl sm:max-w-[40ch]">
					<h1 className="text-2.5xl font-semibold !leading-snug xs:text-2.75xl sm:text-4xl">
						Find the Best{" "}
						<span className="text-accent-foreground">Digital Solutions</span> for Influencers &
						Businesses
					</h1>
					<span className="mt-2 text-sm font-light leading-tight sm:mt-1 sm:text-sm3">
						With our innovative <span className="text-secondary">Technology</span> and
						growth <span className="text-secondary">Marketing</span> services, you can
						grow your business and social media audience from anywhere, at any time
					</span>

					<div className="mt-8 flex h-14 items-center gap-2 rounded-md bg-primary px-4 sm:h-16">
						<Image
							alt=""
							src={SearchIcon}
							draggable="false"
							aria-hidden
							className="w-6"
						/>
						<Input
							placeholder="What are you looking for?"
							value={searchString}
							onChange={(e) => setSearchString(e.target.value)}
							className="border-none text-sm text-primary-foreground placeholder:font-light placeholder:text-grey-foreground"
						/>
						<Button
							className="hidden px-8 py-6 font-normal text-secondary-foreground transition-colors duration-300 sm:inline-flex"
							onClick={() => handleSearch(searchString)}
						>
							Search
						</Button>
					</div>

					<Button
						className="mt-4 h-12 w-full rounded-full text-secondary-foreground shadow-custom xs:h-14 sm:hidden"
						onClick={() => handleSearch(searchString)}
					>
						Search
					</Button>

					<h1 className="mb-1 mt-8 text-sm font-light">Popular Searches</h1>
					<div
						className="z-10 flex h-10 max-w-[calc(100vw-4rem)] gap-2 overflow-x-auto pb-1"
						style={{ scrollbarWidth: "none" }}
					>
						{popularSearch.map((search) => (
							<button
								key={search}
								role="link"
								className="flex items-center justify-center text-nowrap rounded-full bg-secondary/5 px-4 text-xsm2 text-secondary transition-colors duration-300 hover:bg-secondary/20 sm:px-6 "
								onClick={() => {
									setSearchString(search);
									handleSearch(search);
								}}
							>
								{search}
							</button>
						))}
					</div>
				</div>

				<Image
					src={HeroImage}
					alt=""
					aria-hidden
					sizes="75wv"
					quality={100}
					className="hidden w-full self-end xl:block"
				/>

				<Image
					draggable="false"
					src={ButterfliesImage}
					alt=""
					aria-hidden
					sizes="50vw"
					quality={100}
					className="absolute -top-14 left-4 size-24 xs:-top-20 sm:-left-4 sm:-top-8"
				/>

				<Image
					draggable="false"
					src={DiagonalsImage}
					alt=""
					aria-hidden
					sizes="50vw"
					quality={100}
					className="absolute -right-4 -top-8 hidden size-44 sm:block"
				/>

				<Image
					src={LeavesGreyImage}
					alt=""
					draggable="false"
					aria-hidden
					sizes="50vw"
					quality={100}
					className="absolute bottom-0 left-0 size-28 sm:-left-4 sm:size-32"
				/>

				<Image
					draggable="false"
					src={LeavesColorImage}
					alt=""
					aria-hidden
					sizes="50vw"
					quality={100}
					className="absolute bottom-0 right-0 size-24 sm:-right-4"
				/>
			</div>
		</div>
	);
}
